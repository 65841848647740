<template>
  <div class="flex flex-row gap-[5px] items-start">
    <Icon
      v-if="icon"
      :icon-class="icon"
      :classes="`relative left-[2px] shrink-0 mt-[3px] ${
        noClick ? '' : ' text-primary-base'
      }`"
    ></Icon>
    <Icon
      v-else-if="type === 'application/pdf'"
      :classes="`relative left-[2px] shrink-0 mt-[3px] ${
        noClick ? '' : ' text-primary-base'
      }`"
      icon-class="fal fa-file-pdf"
    />
    <Icon
      v-else
      icon-class="fal fa-file-image"
      :classes="`shrink-0 mt-[3px] ${noClick ? '' : ' text-primary-base'}`"
    />
    <span
      class="overflow-hidden break-words break-all text-ellipsis whitespace-nowrap"
      :class="[
        { 'cursor-pointer': !noClick, 'cursor-default': noClick },
        noClick ? '' : 'text-primary-base',
      ]"
      @click="$emit('click')"
      >{{ name }}</span
    >
    <span
      v-if="readableSize || size"
      class="text-sm text-border-dark shrink-0 pr-sm"
      >({{ readableSize ?? humanFileSize(size) }})</span
    >
  </div>
</template>
<script setup lang="ts">
import { Icon } from '@/complib';

defineProps({
  type: {
    type: String,
    required: false,
    default: null,
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  size: {
    type: Number,
    required: false,
    default: 0,
  },
  readableSize: {
    type: String,
    required: false,
    default: null,
  },
  noClick: {
    type: Boolean,
    required: false,
    default: false,
  },
});

defineEmits<{
  (e: 'click'): void;
}>();

function humanFileSize(size: number): string {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    +(size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}
</script>
